/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.border-gains {
  padding-inline: 14px;
  border-bottom: 2px gainsboro dashed;
  padding-bottom: 0.5em;
}

// Alert Component
.alert-apps {
  .alert-input-group {
    padding: 0;
  }

  .alert-input-wrapper {
    textarea {
      margin-block-end: 1em;
      inline-size: 50vw;
      block-size: 30vh;
    }
  }

  .alert-wrapper {
    padding: 24px;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
  }

  .alert-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }

  .alert-head {
    padding: 0;

    h2 {
      margin: 0;
      font-size: 18px;
    }
  }

  .alert-message {
    text-align: left;
    padding: 12px 0 16px 0;
    font-size: 14px;
  }

  .alert-button {
    color: var(--ion-color-light);
    height: auto;
    border: none;
    border-radius: 16px;
    padding: 7px 0;
    flex: 1;
    min-width: auto;
  }

  button.alert-button.ion-activated {
    transition: 0.5s;
    background-color: var(--ion-color-dark);
  }

  button.alert-button-role-oke {
    transition: 0.5s;
    background-color: var(--ion-color-primary);
  }

  button.alert-button-role-cancel {
    transition: 0.5s;
    background-color: var(--ion-color-medium-tint);
  }
}

.padding-footer {
  --padding-bottom: 16px;
  padding: 16px;
}

.container-msg {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin-block-start: 16%;
  align-items: center;

  .content {
    inline-size: 300px;
    margin: auto;
    text-align: center;
  }

  ion-img {
    width: 70%;
    margin: 0 auto;
  }

  h2 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 5vw;
  }

  p {
    font-size: 14px;
    color: var(--ion-color-medium);
    margin: 0;
  }
}

// Ion App Bug
ion-progress-bar,
ion-app {
  background-color: #fff;
}

// Gadai Items
.items-input {
  --background: #f1f1f1;
  border-radius: 7px;
  margin: 16px 0px;

  ion-icon,
  ion-label {
    color: var(--ion-color-medium);
  }

  ::placeholder {
    text-transform: capitalize;
  }
}

ion-searchbar {
  --placeholder-font-weight: 200;
}

ion-avatar {
  --border-radius: 10px;
}

.lelang {
  background: var(--ion-color-danger);
}

.tebus {
  background: var(--ion-color-dark);
}

.aktif {
  background: var(--ion-color-success);
}