// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #cc0b06;
  --ion-color-primary-rgb: 204, 11, 6;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b40a05;
  --ion-color-primary-tint: #d1231f;

  // Yellow Version
	// --ion-color-primary: #ffd239;
	// --ion-color-primary-rgb: 255,210,57;
	// --ion-color-primary-contrast: #000000;
	// --ion-color-primary-contrast-rgb: 0,0,0;
	// --ion-color-primary-shade: #e0b932;
	// --ion-color-primary-tint: #ffd74d;


  /** secondary **/
  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 0, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #000000;
  --ion-color-secondary-tint: #1a1a1a;

  /** tertiary **/
  --ion-color-tertiary: linear-gradient(198deg, #ff847b, #cc0b06);
  --ion-color-tertiary-rgb: 244, 106, 98;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #d75d56;
  --ion-color-tertiary-tint: #f57972;

  // Yellow Version
  // --ion-color-tertiary: linear-gradient(198deg, #faba35, #f9d247);
	// --ion-color-tertiary-rgb: 249,210,71;
	// --ion-color-tertiary-contrast: #000000;
	// --ion-color-tertiary-contrast-rgb: 0,0,0;
	// --ion-color-tertiary-shade: #dbb93e;
	// --ion-color-tertiary-tint: #fad759;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #ffffff;
  --ion-color-light-tint: #ffffff;

  // FONT FAMILY
  --ion-font-family: "sanspro";

  // SET ION PADDING
  --ion-padding: 20px;

  // SET LIGHT CALENDAR DARK MODE
  // --ion-color-step-100: #000; //Bg Calendar
  // --ion-color-step-650: #000; //Month Calendar
  // --ion-color-step-500: #000; //Today Calendar


  // --ion-color-step-50: #fff;
  // // --ion-color-step-100: #fff;
  // --ion-color-step-150: #fff;
  // --ion-color-step-200: #fff;
  // --ion-color-step-250: #fff;
  // --ion-color-step-300: #fff;
  // --ion-color-step-350: #fff;
  // --ion-color-step-400: #fff;
  // --ion-color-step-450: #fff;
  // // --ion-color-step-500: #000;
  // --ion-color-step-550: #fff;
  // --ion-color-step-600: #fff;
  // // --ion-color-step-650: #fff;
  // --ion-color-step-700: #fff;
  // --ion-color-step-750: #fff;
  // --ion-color-step-800: #fff;
  // --ion-color-step-850: #fff;
  // --ion-color-step-900: #fff;
  // --ion-color-step-950: #fff;

}


// Font Source Sans Pro
@font-face {
  font-family: "sanspro";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/sanspro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "sanspro";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/sanspro/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: "sanspro";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/sanspro/SourceSansPro-Black.ttf");
}

// @font-face {
//   font-family: "sanspro";
//   font-style: normal;
//   font-weight: 400;
//   src: url("../assets/fonts/sanspro/SourceSansPro-SemiBold.ttf");
// }

@font-face {
  font-family: "sanspro";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/sanspro/SourceSansPro-Light.ttf");
}

// Font Lora
@font-face {
  font-family: "lora";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/lora/Lora-Regular.ttf");
}

@font-face {
  font-family: "lora";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/lora/Lora-SemiBold.ttf");
}

@font-face {
  font-family: "lora";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/lora/Lora-Bold.ttf");
}